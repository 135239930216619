<template>
	<div id="app">
		<div v-if="!wx" class="wx">
			请在微信中打开页面
		</div>
		<div v-else>
			<nut-watermark class="mark1" v-if="flag" :width="watermarkWidth" z-index="3000" :content="watermark"></nut-watermark>
			<transition :name="viewTransition" :mode="mode"><!-- 元素插入、更新或移除时添加动画效果,在这里放置要应用过渡效果的元素 -->
				<div class="routerView">
					<router-view></router-view>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	import wxsdk from '@/components/wxsdk.js'
export default {
  name: 'app',
  data(){
    return {
      viewTransition:'fade',//淡入淡出效果
	  mode: 'out-in',
      selected: 'index',//???
      tabbarShow: false,
	  watermark: '',
	  flag: false,
	  title: '首页',
	  show: true,
	  wx: false,
	  watermarkWidth: 150,
    }
  },
  watch:{
    $route(to,from){
      //判断是否显示tabbar
      if(to.query.reload){
    	  window.location.href = window.location.href.split('reload=true')[0]
    	  location.reload()
      }
    },
  },
  methods:{
    
	
  },
  mounted() {
	let user = JSON.parse(localStorage.getItem('user'))
	let openid = localStorage.getItem('openid')
	let u = navigator.userAgent;
	let isWenxin = u.toLowerCase().match(/MicroMessenger/i) == "micromessenger"
	if(isWenxin){
		this.wx = true
		wxsdk.getOpenid()
		// wxsdk.getConfig()
		document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
			WeixinJSBridge.call("hideOptionMenu");
		});
		console.log(openid)
		if(!user){
			if(openid){
				this.watermark = openid.slice(openid.length-6,openid.length)+'_'+this.$common.getDateNow('yyyy-MM-dd')
			}
		}else{
			this.watermark = user.phone.slice(0,3)+'XXXX'+user.phone.slice(user.phone.length-4,user.phone.length)+'_'+this.$common.getDateNow('yyyy-MM-dd')
			
		}
		this.watermarkWidth = this.watermark.length*10
		this.flag = true
	}
  },
  created() {
	  this.show = false
  }
}
</script>

<style>
body{
	margin: 0;
	overflow: hidden;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  position: relative;
}
.nut-tabbar-bottom{
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.wx{
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
