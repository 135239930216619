<template>
	<div class="item">
		<div class="tip" @click="event.register" v-if="state.law_sts == '5' && !state.user">请先完成注册和身份核实，才可以查看更多案件信息</div>
		<div class="tip" @click="event.register" v-else-if="state.law_sts == '5'">您还未完成信息填写，点击继续您的填写</div>
		<div class="tip" @click="event.register" v-else-if="state.law_sts == '1'">您还未完成实名认证，点击继续您的实名认证</div>
		<div class="tip" @click="event.register" v-else-if="state.law_sts == '4'">您的账户还未通过我们的法务员的人工审核，点击这里催促他们</div>
		<div class="item_top" v-if="state.law_sts == '2'">
			<div class="top_left">
				<input type="text" class="input_search" placeholder="输入案件名/案件号" @blur="event.search" v-model="state.serachValue">
				<span @click="event.search"><img class="serach_icon" src="../assets/imgs/search.png" alt=""></span>
			</div>
			<div class="top_right"><img class="serach_icon" src="../assets/imgs/serach_icon.png" alt="" @click="event.screen"></div>
		</div>
		<div class="item_tab_span" style="text-align: left;overflow-x: scroll;white-space: nowrap;"  v-if="state.law_sts == '2'">
			<span style="margin-bottom:5px;" v-for="item in state.tabListOnText">{{item}}</span>
		</div>
		<div class="item_tab" :style="'height:calc(100vh - '+(state.law_sts == '0'?50:65)+'px);'"  id="customScroll">
			<nut-infiniteloading
				  load-txt="加载中..."
				  pull-txt="   "
				  load-more-txt="没有啦～"
				  container-id="customScroll"
				  :use-window="false"
				  :is-open-refresh="true"
				  :has-more="state.customHasMore"
				  load-icon="loading1"
				  pull-icon="loading1"
				  @load-more="customLoadMore"
				  @refresh="refresh"
				>
				<div class="project_li" @click="event.detail(item)" v-for="(item,index) in state.list">
					<div class="pro_cont">
						<div :class="{'pro_tit':true,'mosaic':item.params.detailsFlag == '0'}">{{item.caseName.split(item.debtorName)[0]}}<span :class="{'mosaic':item.params.detailsFlag == '1' && item.params.dispatchCaseFlag != '1'}">{{item.debtorName}}</span>{{item.caseName.split(item.debtorName)[1]}}</div>
						<div class="pro_address">债务人所在地：{{item.params.encodeIdCardAddress}}</div>
						<div class="pro_money">金额：<nut-price :price="item.overAmt" :thousands="true" :decimal-digits="(item.overAmt && parseFloat(item.overAmt).toString().split('.')[1])?parseFloat(item.overAmt).toString().split('.')[1].length:0"/></div>
						<div class="pro_date"><span :class="{'mosaic':item.params.detailsFlag == '0'}">逾期日期：{{item.firstOverDate}}</span>（逾期{{item.overDays}}天）</div>
						<div :class="{'item_tab_span':true,'mosaic':item.params.detailsFlag == '0'}" style="text-align: left;overflow-x: scroll;white-space: nowrap;">
							<span style="margin-bottom:5px;" v-if="item.params.encodeTag" v-for="itemChild in item.params.encodeTag.split('|')">{{itemChild}}</span>
						</div>
					</div>
					<div style="text-align: right;" :class="{'mosaic':item.params.detailsFlag == '0'}" v-if="item.params.hasOwnProperty('dispatchCaseFlag') && item.params.detailsFlag == '1' && item.params.dispatchCaseFlag != '1'">
						<span class="tj_btn" @click.stop="event.accept(item,index)">申请接案</span>
					</div>
					<div style="text-align: right;" v-if="item.params.hasOwnProperty('dispatchCaseFlag') && item.params.detailsFlag == '1' && item.params.dispatchCaseFlag == '1'">
						<span class="tj_btn" @click.stop="event.getDetail(item,index)">获取案卷</span>
					</div>
				</div>
			</nut-infiniteloading>
		</div>
		<nut-popup pop-class="pop_cont" position="bottom" :safe-area-inset-bottom="true" closeable round v-model:visible="state.showPop">
			<div class="search_tab">
				<div class="tab_tit">债务人户籍地：</div>
				<div @click="state.showDY = true;state.addressValue=[]">{{state.addressText || '请选择'}}</div>
			</div>
			<div class="search_tab">
				<div class="tab_tit">案件标签（可多选）</div>
				<span v-for="item in state.tablist" :class="{'on':state.tabListOn.indexOf(item.id) != -1}" @click="event.labClick(item)">{{item.tagName}}</span>
			</div>
			<div class="btn_box">
				<span class="tj_btn" @click="event.submit">确定</span>
				<span class="tj_btn hui" @click="event.reset">重置</span>
			</div>
		</nut-popup>
		<nut-popup pop-class="pop_cont" position="bottom" :safe-area-inset-bottom="true" closeable style="height: 80vh;" v-model:visible="state.showDetail">
			<div :class="{'detailTit':true,'mosaic':state.detailItem.params.detailsFlag == '0'}">{{state.detailItem.caseName.split(state.detailItem.debtorName)[0]}}<span :class="{'mosaic': state.detailItem.params.detailsFlag == '1' && state.detailItem.params.dispatchCaseFlag != '1'}">{{state.detailItem.debtorName}}</span>{{state.detailItem.caseName.split(state.detailItem.debtorName)[1]}}</div>
			<detail :id="state.detailItem.id" :data="state.detailItem" v-if="state.detailItem.id" @fileStatus="event.getStatus"></detail>
		</nut-popup>
		<nut-address
			v-model="address"
		    v-model:visible="state.showDY"
			close-btn-icon="checklist"
			:province="state.province"
			:city="state.city"
			:country="state.country"
			:town="state.town"
			@change="event.onChange"
			@close="event.close"
			@close-mask="event.closeMask"
			custom-address-title="请选择地区"
		></nut-address>
		<file-p v-if="state.fileBox" :url="state.fileUrl" @fileStatus="event.getStatus"></file-p>
	</div>
	
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs,ref,onBeforeMount } from 'vue';
	// import city from '@/components/city2.json'
	import { Dialog, Toast } from '@nutui/nutui';
	import detail from '@/view/item/detail'
	import fileP from '@/view/item/fileP'
	export default {
		components:{
			detail,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const address = ref([])
			const state = reactive ({
				customHasMore: true,
				showPop: false,
				showDY: false,
				searchValue: '',
				province: [],
				city: [],
				country: [],
				town:[],
				addressText: '',
				addressValue: [],
				tablist:[
					{labelId:'1',labelName:'sss'}
				],
				tabListOn:[],
				tabListOnText:[],
				law_sts: '5',
				showDetail: false,
				detailItem: {
					params:{
						detailsFlag:'0'
					},
					caseName: '',
					debtorName: ''
				},
				list: [],
				cur: 1,
				cityArr: [],
				serachValue:'',
				user:'',
				fileBox: false,
				fileUrl: ''
			});
			onMounted(()=>{
				state.law_sts = localStorage.getItem('law_sts') || '5'
				state.user = localStorage.getItem('user')
				if(state.user){
					event.getInfoByPhone()
				}else{
					event.icmCaseTag()
					event.getCity()
					setTimeout(function(){
						state.province = state.cityArr
					},1000)
					event.getList(1,20,function(){})
				}
			})
			const customLoadMore = done => {
				state.cur++
				event.getList(state.cur,20,done)
			}
			const refresh = done => {
				state.cur = 1
				state.list = []
				event.getList(state.cur,20,done)
			}
			const event = {
				getDetail(item,index){
					proxy.$axios.createEmailTaskByWx({
						caseIds: item.id,
					}).then(res => {
						if(res.data.code == 200){
							Toast.success('案卷已进入发送队列，邮件发送有延迟，请您稍后查看邮箱，并注意是否在垃圾邮件分类里。')
						}
					})
				},
				closeTip(){
					Dialog({
						title: '提示',
						content: '已停止合作，请勿留存案件信息',
						noCancelBtn: true,
						closeOnClickOverlay: false,
						onOk:function(){
							if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
								WeixinJSBridge.invoke('closeWindow', {}, function(res) {
									if (res.err_msg == "close_window:ok") {
										// 关闭成功的处理
										console.log('关闭成功的处理')
									} else {
										// 关闭失败的处理
										console.log('关闭失败的处理')
										window.location.href = 'about:blank'
										window.close()
									}
								});
							}else{
								window.location.href = 'about:blank'
								window.close()
							}
						}
					});
				},
				getInfoByPhone(){
					proxy.$axios.getInfoByPhone(JSON.parse(state.user).phone).then((res)=>{
						if(res.data.code == 200){
							state.law_sts = res.data.data.lawSts
							localStorage.setItem('law_sts',state.law_sts)
							if(state.law_sts == '3'){
								localStorage.removeItem('token')
								localStorage.removeItem('user')
								localStorage.removeItem('uuid')
								localStorage.removeItem('law_sts')
								event.closeTip()
							}else{
								event.icmCaseTag()
								event.getCity()
								setTimeout(function(){
									state.province = state.cityArr
								},1000)
								event.getList(1,20,function(){})
							}
						}
					})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
				},
				getList(cur,size,fun){
					let caseInfo = {
						debtorIdCardAddress:'',
						params: {}
					}
					caseInfo.tagId = state.tabListOn.join("/")
					state.addressValue.forEach(item=>{
						caseInfo.debtorIdCardAddress = caseInfo.debtorIdCardAddress+item.id+'/'
					})
					caseInfo.debtorIdCardAddress = caseInfo.debtorIdCardAddress.slice(0,caseInfo.debtorIdCardAddress.length-1)
					caseInfo.params.queryParam = state.serachValue
					let queryParams = {}
					queryParams.pageNum = cur
					queryParams.pageSize = size
					queryParams.caseOnlineSts = 0
					queryParams = {...queryParams,...caseInfo}
					Toast.loading('加载中...')
					proxy.$axios.icmCaseInfoList(queryParams).then(res =>{
						if(res.data.code == 200){
							if(cur*size >= res.data.total){
								state.customHasMore = false
							}
							if(cur == 1){
								state.list = res.data.rows
							}else{
								state.list = state.list.concat(res.data.rows)
							}
							Toast.hide()
							fun()
						}else{
							Toast.hide()
						}
					})
				},
				accept(item,index){
					if(item.params.hasOwnProperty('dispatchCaseFlag') && item.params.detailsFlag == '1' && item.params.dispatchCaseFlag != '1'){
						proxy.$axios.updateByLawPhone({
							caseId: item.id,
							sts: "1"
						}).then(res => {
							if(res.data.code == 200){
								Toast.success(res.data.msg)
								proxy.$axios.icmCaseInfoList({pageSize:1,pageNum:1,params:{queryParam:item.caseName}}).then(res =>{
									if(res.data.code == 200){
										state.list[index] = res.data.rows[0]
									}
								})
							}
						})
					}
				},
				search(){
					// if(state.serachValue){
						state.cur = 1
						state.list = []
						event.getList(state.cur,20,function(){})
					// }
				},
				screen(){
					state.showPop = true
				},
				submit(){
					state.showPop = false
					state.cur = 1
					state.list = []
					event.getList(state.cur,20,function(){})
				},
				reset(){
					state.showPop = false
					state.cur = 1
					state.list = []
					state.tabListOn = []
					state.tabListOnText = []
					state.addressValue = []
					event.getList(state.cur,20,function(){})
				},
				onChange(data){
					state[data.next] = data.value.children
					if(state.addressValue.indexOf(data.value) == -1){
						state.addressValue.push(data.value)
					}
					if(!state[data.next]){
						state.showDY = false
					}
				},
				close(data){
					state.addressValue = [data.data.province,data.data.city,data.data.country]
					state.addressText = data.data.province.name+'|'+data.data.city.name+'|'+data.data.country.name
				},
				closeMask(data){
					if(data.closeWay.value == 'cross'){
						// if(state.addressValue.length == 4){
						// 	state.addressText = state.addressValue[0].name+'|'+state.addressValue[1].name+'|'+state.addressValue[2].name+'|'+state.addressValue[3].name
						// 	// address.value=[state.addressValue[0].id,state.addressValue[1].id]+'|'+state.addressValue[2].id+'|'+state.addressValue[3].id
						// }else 
						if(state.addressValue.length == 3){
							state.addressText = state.addressValue[0].name+'|'+state.addressValue[1].name+'|'+state.addressValue[2].name
							// address.value=[state.addressValue[0].id,state.addressValue[1].id]+'|'+state.addressValue[2].id
						}else if(state.addressValue.length == 2){
							state.addressText = state.addressValue[0].name+'|'+state.addressValue[1].name
							// address.value=[state.addressValue[0].id,state.addressValue[1].id]
						}else if(state.addressValue.length == 1){
							state.addressText = state.addressValue[0].name
							// address.value=[state.addressValue[0].id]
						}else{
							state.addressText = ''
						}
					}
				},
				detail(item){
					// if(!state.user){
					// 	Dialog({
					// 		title: '提示',
					// 		content: '案件详情仅限专业人士查看，请注册并完成审核认证后继续查看。有账号的，请直接登录',
					// 		customClass:'dialog_reset',
					// 		okText: '去注册/登录',
					// 		cancelText: '关闭',
					// 		onCancel:function(){
								
					// 		},
					// 		onOk:function(){
					// 			proxy.$router.push({name:'login'})
					// 		}
					// 	});
					// }else{
						state.showDetail = true
						state.detailItem = item
					// }
				},
				register(){
					if(state.law_sts == '5'){
						if(state.user){
							proxy.$router.push({path:'/register'})
						}else{
							proxy.$router.push({path:'/login'})
						}
					}else if(state.law_sts == '1'){
						proxy.$router.push({path:'/register',query:{sm:true}})
					}else if(state.law_sts == '4'){
						location.href = 'https://work.weixin.qq.com/kfid/kfcd4ec604cb328d29e'
					}
				},
				icmCaseTag(){
					proxy.$axios.icmCaseTag().then(res=>{
						if(res.data.code == 200){
							state.tablist = res.data.data
						}
					})
				},
				labClick(item){
					let i = state.tabListOn.indexOf(item.id)
					if(i != -1){
						state.tabListOn.splice(i,1)
						state.tabListOnText.splice(i,1)
						
					}else{
						state.tabListOn.push(item.id)
						state.tabListOnText.push(item.tagName)
					}
				},
				getCity(){
					proxy.$axios.getCity().then(res=>{
						if(res.data.code == 200){
							let city = res.data.data[0].children
							for(var i=0;i<city.length;i++){
								state.cityArr.push({name:city[i].label,id:city[i].id})
								if(city[i].children.length>0){
									state.cityArr[i].children = []
									for(var j=0;j<city[i].children.length;j++){
										state.cityArr[i].children.push({name:city[i].children[j].label,id:city[i].children[j].id})
										if(city[i].children[j].children.length>0){
											state.cityArr[i].children[j].children = []
											for(var m=0;m<city[i].children[j].children.length;m++){
												state.cityArr[i].children[j].children.push({name:city[i].children[j].children[m].label,id:city[i].children[j].children[m].id})
												if(city[i].children[j].children[m].children){
													state.cityArr[i].children[j].children[m].children = []
													for(var d=0;d<city[i].children[j].children[m].children.length;d++){
														state.cityArr[i].children[j].children[m].children.push({name:city[i].children[j].children[m].children[d].label,id:city[i].children[j].children[m].children[d].id})
													}
												}
											}
										}
									}
								}
							}
						}
					})
				}
				
			}
			return { state,event,toRefs,customLoadMore,refresh,detail,address };
		}
	};
</script>

<style>
	.item{
		overflow: hidden;
		background-color: #f7f7f7;
		font-size: 14px;
		height: 100vh;
	}
	.item_top{
		justify-content: space-between;
		padding: 10px 10px 20px;
		display: flex;
		color: #fff;
		align-items: center;
		background-image: url('../assets/imgs/topBg.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100% 80px;
	}
	.item_top div img{
		margin-left: 10px;
		vertical-align: middle;
	}
	.top_left{
		flex: 1;
		border: 1px solid #fff;
		border-radius: 10px;
		text-align: left;
	}
	.top_left span img{
		transform: scale(0.8);
		margin: 0;
	} 
	.input_search{
		border: 1px solid transparent;
		background-color: transparent;
		height: 20px;
		line-height: 20px;
		padding: 5px 10px;
		font-size: 14px;
		outline: none;
		color: #fff;
		width: calc(100% - 60px);
	}
	.input_search::placeholder{
		color: #eee;
	}
	.item_tab{
		height: calc(100vh - 50px);
		overflow-y: scroll;
		overscroll-behavior-y: none;
	}
	.item_tab_span span{
		background: #EAF1FF;
		color: #4E7EE4;
		padding: 4px;
		border-radius: 4px;
		font-size: 12px;
		margin-right: 6px;
		margin-bottom: 5px;
		display: inline-block;
	}
	.project_li{
		display:flex;
		flex-direction: column;
		background: #fff;
		border-radius: 10px;
		margin: 10px 15px 0px;
		padding: 10px;
		text-align: left;
	}
	.pro_cont{
		border-bottom: 1px solid #f7f7f7;
	}
	.pro_cont>div{
		margin: 5px 0;
		color: #999;
	}
	.pro_cont .pro_tit{
		font-size: 16px;
		color: #333;
	}
	.tj_btn{
		display: inline-block;
		width: 75px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		background-color: #6692EF;
		color: #fff;
		border-radius: 4px;
		font-size: 12px;
		margin-top: 10px;
	}
	.tj_btn.hui{
		background-color: #ddd;
	}
	.search_tab{
		text-align: left;
		margin: 0 15px;
	}
	.tab_tit{
		margin: 20px 0;
		font-size: 15px;
	}
	.search_tab span{
		display: inline-block;
		padding: 0 8px;
		font-size: 14px;
		height: 20px;
		line-height: 20px;
		border-radius: 10px;
		border: 1px solid #ddd;
		margin-right: 5px;
		color: #888;
		max-width: 240px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.search_tab span.on{
		color: #6c95ea;
		border-color: #6c95ea;
	}
	.btn_box{
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-bottom: 20px;
	}
	.tip{
		background: rgb(72 192 8);
		color: #fff;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
	}
	.mosaic{
		filter: blur(4px);
	}
	.detailTit{
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		text-align: left;
		padding-left: 20px;
		margin-top: 7px;
	}
	.pro_money .nut-price{
		color: #999;
	}
	.pro_money .nut-price--large, .nut-price--point{
		font-size: 14px;
	}
	.pro_money .nut-price--decimal-large, .nut-price--symbol-large{
		font-size: 14px !important;
	}
</style>