import axios from 'axios'
import Qs from 'qs'
import {Toast} from '@nutui/nutui';
import router from '@/router'


let baseURL = ''
let sys = ''
//配置开发环境
if (process.env.NODE_ENV === 'development') {
 //    baseURL = 'http://172.17.3.66';
	// sys = 'dev-api'
	baseURL = 'https://lcmgzh.npa.icu';
	sys = 'prod-api'
}
 
// 配置生产环境
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://lcmgzh.npa.icu';
  // baseURL = 'https://lcmoa.npa.icu';
  sys = 'prod-api'
}

const instance = axios.create({
	timeout: 1800000
})

if(localStorage.getItem('token')){
	instance.interceptors.request.use(config => {
		config.headers['Authorization'] = localStorage.getItem('token')
		return config
	})
}

instance.interceptors.request.use((config)=>{
	if(!config.headers['Content-Type']){
	  config.headers['Content-Type'] = 'application/json'
	}
	return config
})

function tansParams(params) {
  let result = ""
  for (let k of Object.keys(params)) {
    const value = params[k]
    let parent = encodeURIComponent(k) + '='
    if (value !== undefined && value !== null && value !== '')
      if (typeof value === 'object') {
        for (let key of Object.keys(value)) {
          if (value[key] !== undefined && value[key] !== null && value[key] !== '') {
            let params = k + "[" + key + "]"
            let subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += parent + encodeURIComponent(value) + '&'
      }
  }
  return result
}

instance.interceptors.request.use((config) => {
    if (config.method === 'get' && config.params) {
		let url = config.url + '?' + tansParams(config.params);
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);


instance.interceptors.response.use((res)=>{
	if (res.data.code) {
	  let code = res.data.code
	  if(code == '401'){
		  localStorage.removeItem('user')
		  localStorage.removeItem('law_sts')
		  localStorage.removeItem('uuid')
		  localStorage.removeItem('token')
		  router.replace({
		    path: 'login'
		  })
	  }
	  if(code != '200'){
		  if(res.config.url.indexOf('getInfoByPhone') == -1){
			Toast.fail(res.data.msg)
		  }
	  }
	}
	return res
})

const api = { 
	baseURL,
	getSignatureGzh(params, config) { //微信获取临时票据
		return axios.get(`${baseURL}/${sys}/gzh/wx/jsapi/getAccessToken`,{params: params}, config)
	},
	getOpenid(params) { //微信获取openid
		return axios.get(`${baseURL}/${sys}/gzh/wx/redirect/getOpenId`, {params: params})
	},
	smsCode(params) {//获取验证码
		return instance.post(`${baseURL}/${sys}/auth/smsCode`, params)
	},
	login(params) {//登录
		params.uuid = localStorage.getItem('uuid')
		return instance.post(`${baseURL}/${sys}/auth/login`, params)
	},
	icmLawBaseInfo(params) {//完善信息
		return instance.post(`${baseURL}/${sys}/system/icmLawBaseInfo/putLawBaseInfoByOpenId`, params)
	},
	getIcmDebtorInfoByCaseId(params) {//查看债务人信息
		return instance.get(`${baseURL}/${sys}/system/icmDebtorInfo/getIcmDebtorInfoByCaseId/${params}`)
	},
	getIcmPactInfoByCaseId(params) {//查看合同详情
		return instance.get(`${baseURL}/${sys}/system/icmPactInfo/getInfoByCaseId/${params}`)
	},
	getIcmAmtInvolvedByCaseId(params) {//查看金额
		return instance.get(`${baseURL}/${sys}/system/icmAmtInvolved/getInfoByCaseId/${params}`)
	},
	icmCaseLogList(params) {//查看日志
		return instance.get(`${baseURL}/${sys}/system/icmCaseLog/list`,{params: params})
	},
	icmCaseInfoList(params) {//案件列表
		return instance.get(`${baseURL}/${sys}/system/icmCaseInfo/selectListToWXDisplay`,{params: params})
	},
	getDict(params) {//获取字典
		return instance.get(`${baseURL}/${sys}/system/dict/data/type/${params}`)
	},
	getAll(){
		return instance.get(`${baseURL}/${sys}/system/icmCreditorInfo/getAll`)
	},
	getCity(){
		return instance.get(`${baseURL}/${sys}/system/district/treeSelect`)
	},
	selectInfosByProjectId(params){ //获取照片
		return instance.get(`${baseURL}/${sys}/system/projectInformation/selectInfosByProjectId`,{params: params})
	},
	getOssStsConfig(params){
		return instance.get(`${baseURL}/${sys}/file/oss/getOssStsConfig/${params}`)
	},
	icmCaseTag(){
		return instance.get(`${baseURL}/${sys}/system/icmCaseTag/getList`)
	},
	getLiteToken(params){//实名认证
		return instance.post(`${baseURL}/${sys}/system/ks/getLiteToken?userId=${params.userId}`)
	},
	getLiteResult(params){//获取实名认证结果
		// return instance.post(`${baseURL}/${sys}/system/ks/getLiteResult`,params)
		return instance.post(`${baseURL}/${sys}/system/ks/getLiteResult?bizNo=${params.bizNo}&bizId=${params.bizId}`)
		// 
	},
	getInfoByPhone(params){//获取律师信息
		return instance.get(`${baseURL}/${sys}/system/icmLawBaseInfo/getInfoByPhone/${params}`)
	},
	updateByLawPhone(params){//申请接案
		return instance.post(`${baseURL}/${sys}/system/icmLawCaseRecord/updateByLawPhone`,params)
	},
	createEmailTaskByWx(params){//获取案卷
		return instance.post(`${baseURL}/${sys}/system/icmFileSendRecord/createEmailTaskByWx`,params)
	},
	instance: instance

}
export default api