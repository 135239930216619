<template>
	<div class="register">
		<span @click="event.skip" class="skip">暂时跳过，联系客服</span>
		<div class="info_box">
			<div class="label_box">
				<div><span style="color: red;">*</span>您的姓名</div>
				<div class="input_box">
					<input type="text" placeholder="会用于实名认证,所以请赐真实姓名" v-model="state.info.lawName"/>
				</div>
			</div>
			<div class="label_box">
				<div>您的所属律所</div>
				<div class="input_box">
					<input type="text" placeholder="可后续补充律所信息" v-model="state.info.remark"/>
				</div>
			</div>
			<div class="label_box">
				<div>您的工作邮箱</div>
				<div class="input_box">
					<input type="text" placeholder="用于接收卷宗" v-model="state.info.email"/>
				</div>
			</div>
			<div class="label_box">
				<div><span style="color: red;">*</span>您可受理/接案的区域</div>
				<div class="input_box" style="height: auto;min-height: 30px;" @click="event.clickCustomHandle">
					<div class="tabBox" v-for="(item,index) in state.iconItemListOn">
						<span>{{item.name}}</span>
						<nut-icon name="close" size="8" color="#aaa" @click.stop="event.close(index)"></nut-icon>
					</div>
				</div>
				<tree v-if="state.cityState" :show="state.showTree" :city="state.city" :defaultCheckedKeys="state.iconItemListOn" :submit="event.submit" :cancel="event.cancel"></tree>
			</div>
			<div class="label_box">
				<div><span style="color: red;">*</span>您的身份证号（用于实名认证）</div>
				<div class="input_box">
					<input type="text" placeholder="会用于实名认证,所以请赐真实身份证号" v-model="state.info.idNum">
				</div>
			</div>
			<div class="label_box">
				<div>您的律师执业证号（如有）</div>
				<div class="input_box">
					<input type="text" placeholder="会用于实名认证,请赐真实证号" v-model="state.info.lawCardNum">
				</div>
			</div>
			<div class="label_box" style="height: 120px;">
				<div>您的律师证照片</div>
				<div :class="{'upload_btn':true,'opacity':state.imgUrl}" style="position: absolute;top: 27px;left: 20px;z-index: 1;">
					<nut-icon name="uploader"></nut-icon>
					<input type="file" @change="event.upload" accept="image/*">
				</div>
				<div class="upload_btn" v-if="state.imgUrl">
					<img :src="state.imgUrl" alt="">
				</div>
			</div>
		</div>
		<div class="next_btn" @click="event.next">下一步，开始实名认证</div>
		<div class="tip_info">说明：我方重视履行互联网信息安全以及相关隐私保护法规。请您如实填写有关信息。</div>
		<nut-popup pop-class="pop_cont" position="center" closeable style="height: 60vh;" v-model:visible="state.faceTipState">
			<div class="faceTip">
				<div class="faceTit">在开始实名认证之前，您需要准备：</div>
				<div class="faceBox">
					<div>1. 您的身份证原件，我们将拍摄正反面用于核实您的真实身份。</div>
					<img src="/src/assets/imgs/sfz.png" alt="">
					<div>2. 光线充足的环境，我们将进行人脸识别。</div>
					<img src="/src/assets/imgs/rl.png" alt="">
				</div>
				<div class="footer_btn">
				  <nut-button @click.stop="" class="cancel_btn" type="default">取消</nut-button>
				  <nut-button @click="" class="submit_btn" type="primary">提交</nut-button>
				</div>
			</div>
		</nut-popup>
	</div>
</template>

<script>
	import { reactive, onBeforeMount, getCurrentInstance,createVNode, ref,onMounted,nextTick} from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import tree from "@/view/user/tree"
	import oss from 'ali-oss'
	export default {
		components: {
			tree
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const imgClick = ref(null);
			const state = reactive ({
				lightTheme: false,
				iconItemList: [
					{ name: '选项一' },
					{ name: '选项二' },
					{ name: '选项三' }
				],
				iconItemListOn :[],
				info:{
					lawName: '',
					email:'',
					serviceArea: '',
					lawCardNum:'',
					remark:'',
					idNum: '',
					lawCardPhtot: ''
				},
				imgUrl: '',
				showTree: false,
				user: '',
				city: [],
				cityState: false,
				oss: '',
				faceTipState: false,//实名认证提示框
				rl: require('/src/assets/imgs/rl.png'),
				sfz: require('/src/assets/imgs/sfz.png')
			})
			onMounted(() => {
				state.user = JSON.parse(localStorage.getItem('user'))
				event.getInfoByPhone()
				if(proxy.$route.query.sm){
					// event.getLiteToken()
				}else if(Object.keys(proxy.$route.query).length != 0 && Object.keys(proxy.$route.query).indexOf('reload') == -1){
					event.getLiteResult()
				}
				event.getCity()
				setTimeout(function(){
					state.cityState = true
				},1000)
			});
			const event = {
				getInfoByPhone(){
					proxy.$axios.getInfoByPhone(state.user.phone).then((res)=>{
						if(res.data.code == 200){
							state.info = res.data.data
							res.data.data.serviceAreas.forEach((item,index)=>{
								state.iconItemListOn.push({key:item,name:res.data.data.serviceArea.split('|')[index]})
							})
							event.selectInfosByProjectId(res.data.data.id,'law_card')
						}
					})
				},
				objKeySort(arr) {
					return arr.sort(event.sortByQueryParams('key'))
				},
				sortByQueryParams(property, asc = true) {//默认升序
				  asc = asc == true ? -1 : 1  
				  return function (value1, value2) {
				     let a = value1[property]
				     let b = value2[property]
				     return a < b ? asc : a > b ? asc * -1 : 0
				  }
				},
				async submit(data){
					state.showTree = false
					let dataNew = await event.objKeySort(data)
					let key = ''
					let keyC = ''
					state.iconItemListOn = []
					for(var i=0;i<data.length;i++){
						if(data[i].level == 0){
							state.iconItemListOn.push(data[i])
							break
						}
						if(data[i].level == 1){
							key = (data[i].key+'').slice(0,2)
							state.iconItemListOn.push(data[i])
						}
						if(data[i].level == 2){
							if(key != (data[i].key+'').slice(0,2)){
								state.iconItemListOn.push(data[i])
							}
							keyC = (data[i].key+'').slice(0,4)
						}
						if(data[i].level == 3){
							if(keyC != (data[i].key+'').slice(0,4)){
								state.iconItemListOn.push(data[i])
							}
						}
					}
				},
				cancel(){
					state.showTree = false
				},
				skip(){
					window.location.href = 'https://work.weixin.qq.com/kfid/kfcd4ec604cb328d29e'
				},
				next(){
					state.info.serviceArea = ''
					state.iconItemListOn.forEach(item=>{
						state.info.serviceArea = state.info.serviceArea+item.key+'|'
					})
					state.info.serviceArea = state.info.serviceArea.slice(0,state.info.serviceArea.length-1)
					state.info.lawTel = state.user.phone
					state.info.lawSts = '1'
					if(state.info.lawName == ''){
						Toast.warn('请输入您的姓名')
						return
					}
					if(state.info.idNum == ''){
						Toast.warn('请输入您的身份证号')
						return
					}
					if(state.info.serviceArea == ''){
						Toast.warn('请选择可接案区域')
						return
					}
					proxy.$axios.icmLawBaseInfo(state.info).then(res=>{
						if(res.data.code == 200){
							localStorage.setItem('law_sts','1')
							Dialog({
								content:'<div class="faceTip"><div class="faceTit">在开始实名认证之前，您需要准备：</div><div class="faceBox"><div>1. 您的身份证原件，我们将拍摄正反面用于核实您的真实身份。</div><img style="margin: 10px 0;" src="'+state.sfz+'" alt=""><div>2. 光线充足的环境，我们将进行人脸识别。</div><img src="'+state.rl+'" alt=""></div></div>',
								onCancel:function(){
									
								},
								onOk:function(){
									event.getLiteToken()
								}
							});
						}
					})
				},
				clickCustomHandle(){
					state.showTree = true
				},
				close(id){
					state.iconItemListOn.splice(id,1)
				},
				clickPopOn(obj,id){
					if(state.iconItemListOn.indexOf(obj.name) != -1){
						let i = state.iconItemListOn.indexOf(obj.name)
						state.iconItemListOn.splice(i,1)
					}else{
						state.iconItemListOn.push(obj.name)
					}
				},
				getCity(){
					proxy.$axios.getCity().then(res=>{
						if(res.data.code == 200){
							let city = res.data.data
							for(var i=0;i<city.length;i++){
								state.city.push({name:city[i].label,nodeKey:city[i].id})
								if(city[i].children.length>0){
									state.city[i].children = []
									for(var j=0;j<city[i].children.length;j++){
										state.city[i].children.push({name:city[i].children[j].label,nodeKey:city[i].children[j].id})
										if(city[i].children[j].children.length>0){
											state.city[i].children[j].children = []
											for(var m=0;m<city[i].children[j].children.length;m++){
												state.city[i].children[j].children.push({name:city[i].children[j].children[m].label,nodeKey:city[i].children[j].children[m].id})
												if(city[i].children[j].children[m].children){
													state.city[i].children[j].children[m].children = []
													for(var d=0;d<city[i].children[j].children[m].children.length;d++){
														state.city[i].children[j].children[m].children.push({name:city[i].children[j].children[m].children[d].label,nodeKey:city[i].children[j].children[m].children[d].id})
													}
												}
											}
										}
									}
								}
							}
						}
					})
				},
				upload(file){
					Toast.loading('上传中...')
					event.getOssStsConfig(function(){
						event.putObject(file.target.files[0])
					})
				},
				getOssStsConfig(fun){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.code == 200){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							fun && fun()
						}
					})
				},
				putObject(data){
					let videoName = proxy.$common.getDateNow('yyyyMMdd') + '/' + Date.now() + '_' + data.name
					state.oss.put(
					  videoName,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						event.modifyAvatar(result,data)
					  }
					}).then(function (r2) {
					}).catch(function (err) {
					});
				},
				modifyAvatar(data,file){
					state.imgUrl = state.oss.signatureUrl(data.name)
					let type = file.name.split('.')
					console.log(type[type.length-1])
					state.info.params = {
						'uploadInfo': {
							scNo: 'law_card',
							fileName: file.name,
							fileType: type[type.length-1],
							fileUploadAddress: data.name,
						}
					}
				},
				getLiteToken(){
				  proxy.$axios.getLiteToken({userId:state.user.phone}).then((res) => {
				    if(res.data.code == 200){
				      if(res.data.data.status == 'SUCCESS'){
				        window.localStorage.setItem('face',JSON.stringify({bizNo:res.data.data.bizNo,bizId:res.data.data.bizId}))
						window.localStorage.setItem('sm',(proxy.$route.query.sm?true:false))
				        window.location.href = "https://api.megvii.com/faceid/lite/do?token="+res.data.data.token+'&random='+Math.random()
				      }else{
				        Toast.fail('请求失败')
				      }
				    }
				  })
				},
				getLiteResult(){
					let obj = JSON.parse(localStorage.getItem('face'))
					proxy.$axios.getLiteResult({bizNo:obj.bizNo,bizId:obj.bizId}).then((res) => {
						localStorage.removeItem('face')
						if (res.data.code == 200) {
							if(res.data.data.verifyCode == 'PASS'){
								// proxy.$axios.icmLawBaseInfo({lawSts:'1',lawTel:state.user.phone}).then(res=>{
								// 	if(res.data.code == 200){
								// 		localStorage.setItem('law_sts','1')
										proxy.$router.replace('/')
								// 	}
								// })
							}else{
								if(localStorage.getItem('sm') == 'true'){
									Toast.fail('识别失败')
									localStorage.removeItem('sm')
									setTimeout(function(){
										proxy.$router.replace('/')
									},1000)
								}else{
									Toast.fail('识别失败')
								}
							}
						}else{
						  Toast.fail(res.data.errorMsg)
						}
					  })
				},
				selectInfosByProjectId(id,type){
					proxy.$axios.selectInfosByProjectId({projectDetailsId:id,scNo:type}).then(res =>{
						if(res.data.code == 200){
							if(res.data.data.length>0){
								event.getOssStsConfig(function(){
									state.imgUrl = state.oss.signatureUrl(res.data.data[0].fileUploadAddress)
									state.info.params = {
										'uploadInfo': {
											scNo: 'law_card',
											fileName: res.data.data[0].fileName,
											fileType: res.data.data[0].fileType,
											fileUploadAddress: res.data.data[0].fileUploadAddress,
										}
									}
								})
							}
						}
					})
				},
			}
			return {
				state,
				event
			};
		}
	}
		
</script>

<style>
	.opacity{
		opacity: 0;
	}
	.register{
		position: relative;
		font-size: 14px;
		color: #333;
		text-align: left;
		background: #f7f7f7;
		overflow: auto;
		height: 100vh;
	}
	.skip{
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.info_box{
		margin: 50px 0px 50px;
		
	}
	.label_box {
		background: #fff;
		padding: 10px 20px;
		position: relative;
	}
	.label_box .input_box{
		min-height: 30px;
		line-height: 30px;
		margin-top: 10px;
		border-radius: 5px;
		border: 1px solid #777;
		display: flex;
		overflow-x: scroll;
	}
	.input_box input{
		outline: none;
		width: calc(100% - 10px);
		padding-left: 10px;
		border: none;
		background: transparent;
	}
	.tabBox{
		padding: 0px 10px;
		color: #6c95ea;
		background-color: #EDEFFF;
		border-radius: 4px;
		font-size: 12px;
		margin-right: 5px;
		white-space: nowrap;
		display: inline-block;
	}
	.tabBox span{
		margin-right: 5px;
	}
	.pop{
		box-shadow: 1px 1px 5px #777;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 72px;
		left: 20px;
		width: calc(100% - 40px);
		z-index: 1;
		background: #fff;
	}
	.pop_li{
		padding-left: 10px;
		height: 30px;
		line-height: 30px;
		border-bottom:1px solid #f7f7f7;
		display: flex;
		justify-content: space-between;
	}
	.pop_li.on{
		color: #6c95ea;
		background-color: #EDEFFF;
	}
	.tip_info{
		margin: 0 20px;
		color: coral;
	}
	.faceTip{
		text-align: left;
	}
	.faceTit{
		font-size: 14px;
		margin-bottom: 10px;
	}
	.faceTip img{
		width: 80px;
	}
</style>