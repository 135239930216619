<template>
  <div class="login">
	<div class="login_content">
		<div class="login_frist">
			<img src="../../assets/imgs/logo.png" alt="" class="logo">
			<input class="input_tel" v-model="state.phone" type="tel" maxlength="11" placeholder="请输入您的手机号">
			<div class="next_btn" @click="event.next">下一步</div>
			<div class="check_box">
			  <input type="checkbox" class="checkbox_base" v-model="state.xy">
			  <div>
			    <p>我已阅读并同意</p>
			    <div>
			      <p class="txt_base_color" @click="event.userBook(index)" v-for="(item,index) in state.xyList">《{{item.name}}》</p>
			    </div>
			  </div>
			</div>
		</div>
	</div>
	<nut-popup pop-class="xyPop" closeable v-model:visible="state.loginXyState">
	  <iframe :src="state.xyList[state.xyIndex].url" frameborder="0"></iframe>
	</nut-popup>
	
	<!-- <nut-popup pop-class="pop_cont" position="bottom" closeable style="height: 80vh;" v-model:visible="state.showDetail">
		<div class="detailTit">{{state.detailItem.caseName}}</div>
		<detail :id="state.detailItem.id" v-if="state.detailItem.id"></detail>
	</nut-popup> -->
  </div>
</template>

<script>
	import { reactive, onMounted, ref, getCurrentInstance } from 'vue';
	import { Toast,Dialog } from '@nutui/nutui';
	export default {
		setup() {
			const attr = ref(null)
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				phone: '',
				xyIndex: 0,
				loginXyState: false,
				xy: false,
				xyList:[
				  {name:'炼金良匠隐私政策',url:proxy.$axios.baseURL+'/privacy_ljlj.html'},
				  {name:'炼金良匠平台用户使用服务协议',url:proxy.$axios.baseURL+'/service_ljlj.html'},
				  {name:'炼金良匠平台保密协议',url:proxy.$axios.baseURL+'/secrecy_ljlj.html'}
				],
			});
			onMounted(()=>{
				
			});
			const event = {
				next(){
					let mobileReg = /^1[3-9]\d{9}$/
					if(!mobileReg.test(state.phone)){
						Toast.fail('请输入正确的手机号！')
						return
					}
					if(!state.xy){
						Toast.fail('请勾选协议')
						return
					}
					// proxy.$axios.getInfoByPhone(state.phone).then((res)=>{
					// 	if(res.data.code == 200){
					// 		localStorage.setItem('law_sts',res.data.data.lawSts)
					// 		if(res.data.data.lawSts == '3'){
					// 			event.closeTip()
					// 		}else{
								
					// 		}
					// 	}else{
							proxy.$axios.smsCode({phonenumber:state.phone,loginType:'3'}).then(res=>{
								if(res.data.code == '200'){
									Toast.success('验证码发送成功请注意查收')
									localStorage.setItem('uuid',res.data.data.uuid)
									proxy.$router.push({name:'loginNext',query:{phone:state.phone}})
								}
							})
					// 	}
					// })
				},
				closePopup(el){
				  state[el] = false
				},
				userBook(index) {
				  state.loginXyState = true
				  state.xyIndex = index
				},
				closeTip(){
					Dialog({
						title: '提示',
						content: '已停止合作，请勿留存案件信息',
						noCancelBtn: true,
						closeOnClickOverlay: false,
						onOk:function(){
							window.close()
						}
					});
				}
			}
			return { 
				state,
				event
			};
		}
	}
	
</script>

<style>
	.login{
		background-image: url('../../assets/imgs/top_bg.png');
		background-repeat: no-repeat;
		background-size: 100% 200px;
		background-position: top left;
		background-color: #fff;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
	}
	.login .logo{
		margin: 30px auto 45px;
		width: 109px;
		height: 100px;
		display: block;
		border-radius: 20px;
	}
	.input_tel{
		width: 80vw;
		height: 50px;
		line-height: 50px;
		padding: 0 5vw;
		border-radius: 25px;
		background-color: #F6F6F8;
		color: #333;
		font-size: 14px;
		outline: none;
		border: none;
	}
	.next_btn{
		width: 90vw;
		margin: 20px auto;
		height: 50px;
		line-height: 50px;
		color: #fff;
		background-color: #5484EA;
		border-radius: 25px;
		text-align: center;
	}
	.xyPop{
	  width: 100%;
	  height: 100%;
	}
	.xyPop iframe{
	  width: 100%;
	  height: 100%;
	  padding-top: 25px;
	}
	.check_box {
		display: flex;
		margin-left: 40px;
		align-items: start;
		text-align: left;
		width: 90vw;
		margin: 0 auto;
	}
	.check_box input {
		margin-right: 10px;
	}
	.check_box p {
		margin: 0;
		font-size: 12px;
	}
	.txt_base_color{
		color: #5484EA;
	}
</style>