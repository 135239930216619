<template>
  <!-- 白名单 页面 -->
  <!-- <div class="content"> -->
    <nut-popup :close-on-click-overlay="false" round class="popup_con" :safe-area-inset-bottom="true"  position="bottom" :style="{ height: '80%' }"
      v-model:visible="show">
      <div class="demo">
        <vir-tree  ref="virTreeOne" show-checkbox :source="list" :default-checked-keys="defaultCheckedKeys" @checkChange=checkChangeHandle()>
		</vir-tree>
      </div>
      <div class="footer_btn">
        <nut-button @click.stop="handleCancel" class="cancel_btn" type="default">取消</nut-button>
        <nut-button @click="handleSubmit" class="submit_btn" type="primary">提交</nut-button>
      </div>
    </nut-popup>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted, nextTick, onUpdated } from 'vue'
import { VirTree } from '@ysx-libs/vue-virtual-tree';
// 弹窗是否展示
const props = defineProps({
	defaultCheckedKeys: Array,
	show:Boolean,
	submit: Function,
	cancel: Function,
	city: Array
})
let show = ref(false)
let list = ref([]);
const virTreeOne = ref(null);
let defaultCheckedKeys = ref([]);
let checkRadio = ref(false)

onMounted(() => {
	list.value = props.city
	getListLength(list.value)
});
onUpdated(()=>{
	show.value = props.show
	list.value = props.city
	defaultCheckedKeys.value = []
	props.defaultCheckedKeys.forEach(item=>{
		defaultCheckedKeys.value = defaultCheckedKeys.value.concat([item.key])
	})
})
// 获取tree 数据总条数
let listLength = ref(0)
let getListLength = (row) => {
  row.forEach(ele => {
    listLength.value++
    if (ele.children) {
      getListLength(ele.children)
    }
  });
}

// 获取选中数据
const checkedNodes = () => {
  const checks = virTreeOne.value.getCheckedNodes();
  props.submit(checks)
}

// 提交按钮
const handleSubmit = () => {
  checkedNodes()
}
// 取消按钮
const handleCancel = () => {
 show.value = false
 props.cancel()
}

// 属性组件选中复选框事件
const checkChangeHandle = () => {
  if (virTreeOne.value.getCheckedNodes().length == listLength.value) {
    checkRadio.value = true
  }
  if (virTreeOne.value.getCheckedNodes().length != listLength.value) {
    checkRadio.value = false
  }
}
// tree 组件结束
</script>
<style scoped>
.content {
  position: relative;
  /* height: 100vh; */
}

.up_img {
  width: 12px;
  height: 12px;
  margin-right: 2%;
}

.down_img {
  width: 12px;
  height: 16px;
  margin-right: 2%;

}

.demo{
	text-align: left;
}
.vir-tree-wrap .vir-tree-node .node-arrow {
  position: absolute !important;
  right: 0 !important;
}
.nut-popup {
  padding: 16px 16px 64px 16px;
  box-sizing: border-box;
}
.check_all_box {
  height: 48px;
  display: flex;
  align-items: center;
}
.nut-checkbox__label {
	margin-left: 4px !important;
}
.check_all {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0px;
	line-height: 16px;
	color: rgba(51, 51, 51, 1);
}
.footer_btn {
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  box-shadow: 0px -20px 10px #f8f8f8;
}
.cancel_btn {
	width: 164px;
	height: 36px;
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
	border-radius: 51px;
	margin: 6px 16px 6px 15px;
}
.submit_btn {
	width: 164px;
	height: 36px;
	font-size: 14px;
	border-radius: 51px;
	margin: 6px 16px 6px 0px;
}
.vir-tree {
  max-height: calc(80% - 80px) !important;
}
.vir-tree-node {
	border-bottom: 1px solid #ECECEC;
	min-height: 32px;
	line-height: 32px;
}
.iconzhankai:before {
	font-size: 16px;
}
.vir-checkbox .inner {
	border-radius: 50% !important;
	width: 14px;
	height: 14px;
}
.vir-checkbox.checked .inner {
	border-color: #DE1627;
	background-color: #DE1627;
}
.vir-checkbox.half-checked .inner:after {
	background-color: #DE1627 !important;
}
</style>
<style>
	.vir-tree-wrap{
		padding-bottom: 70px;
	}
</style>
