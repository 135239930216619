import api from '@/api/index.js'
const common = {
	getDateNow(fmt){
		var date = new Date()
		var o = { 
			"M+" : date.getMonth()+1,                 //月份 
			"d+" : date.getDate(),                    //日 
			"h+" : date.getHours(),                   //小时 
			"m+" : date.getMinutes(),                 //分 
			"s+" : date.getSeconds(),                 //秒 
			"q+" : Math.floor((date.getMonth()+3)/3), //季度 
			"S" : date.getMilliseconds()             //毫秒 
		}; 
		if(/(y+)/.test(fmt)) {
			fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
		}
		for(var k in o) {
			if(new RegExp("("+ k +")").test(fmt)){
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
			}
		}
		return fmt; 
	},
	getDateFormat(date,fmt){
		var o = {
			"M+" : date.getMonth()+1,                 //月份 
			"d+" : date.getDate(),                    //日 
			"h+" : date.getHours(),                   //小时 
			"m+" : date.getMinutes(),                 //分 
			"s+" : date.getSeconds(),                 //秒 
			"q+" : Math.floor((date.getMonth()+3)/3), //季度 
			"S" : date.getMilliseconds()             //毫秒 
		}; 
		if(/(y+)/.test(fmt)) {
			fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
		}
		for(var k in o) {
			if(new RegExp("("+ k +")").test(fmt)){
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
			}
		}
		return fmt; 
	},
	async getDict(type){
		return await api.getDict(type).then(res=>{
			 if(res.data.code == 200){
				return res.data.data
			 }
		 })
	},
	getLabel(value,data){
		let v = ''
		data.forEach(item=>{
			if(item.dictValue == value){
				v = item.dictLabel
			}
		})
		return v
	},
	async getAll(type){
		return await api.getAll().then(res=>{
			 if(res.data.code == 200){
				return res.data.data
			 }
		 })
	}
}
export default common