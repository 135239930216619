//  wetchat.js  －－ 个人封装
import wx from 'jweixin-1.6.0'; // 这是自己优化的sdk方法，可以解决ios签名报错的一些问题
import api from '@/api/index.js'
// import wx from 'weixin-js-sdk'; // 这是官方的微信sdk引入方法
function getConfig(){
  let config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  api.getSignatureGzh({url:window.location.href.split('#')[0]},config).then((res) =>{
    if(res.data.code == 200){
      let list = res.data.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx08ede1e77ceeb7b1", // 必填，公众号的唯一标识
        timestamp: list.timestamp, // 必填，生成签名的时间戳
        nonceStr: list.noncestr, // 必填，生成签名的随机串
        signature: list.signature, // 必填，签名
        jsApiList: [ // 用的方法都要加进来
          'hideMenuItems',
		  'hideOptionMenu'
        ],
		openTagList: ['']//wx-open-launch-weapp：打开微信小程序，wxpay：微信支付
      });
	  wx.ready(function(){
		  wx.hideOptionMenu()
	  })
    }
  }).catch(res =>{

  })
}
function getLocation(fun) {
	if(window.localStorage.getItem('gps')){
		return
	}
  wx.ready(function () {
    //获取地理位置
    wx.getLocation({
      type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
      success: function (res) {
		fun(res)
      }
    });
  });
}
function scanQRCode(fun) {
  wx.ready(function () {
    //扫一扫
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {//res里面有啥东西
        fun(res)
      }
    });
  });
}
function getOpenid(){
	let config = {
	  headers: {
	    'Content-Type': 'application/json',
	  }
	};
	if(window.localStorage.getItem('openid')){
		return
	}
	if(window.location.href.indexOf("code") > -1){// openId具体怎么获取的
		api.getOpenid({code: window.location.href.split('code=')[1].split('&')[0]}).then((res) =>{
			if(res.data.code == 200){
				window.localStorage.setItem('openid',res.data.data)
				window.location.reload()
			}
		})
	}else{
		window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx08ede1e77ceeb7b1&redirect_uri='+encodeURI(window.location.href.split('#')[0])+'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
	}
}
export default{
    getConfig,
    scanQRCode,
    getLocation,
	getOpenid
}
