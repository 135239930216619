<template>
	<div class="detail">
		<div class="tab" ref="myElement">
			<nut-tabs v-model="state.tab1value" title-gutter="10" background="#f7f7f7" color="#919191" sticky :top="state.topV">
				<template v-slot:titles>
					<div class="nut-tabs__titles-item" @click="event.tabClick(item)" :class="{active:state.tab1value==item.paneKey}" :key="item.paneKey"  v-for="item in state.list4">
						<span class="nut-tabs__titles-item__text">{{item.title}}</span>
						<span class="nut-tabs__titles-item__line"></span>
					</div>
				</template>
				<nut-tabpane>
					<template v-slot:default v-if="state.tab1value == 0">
						<!-- filter: blur(4px); -->
						<div class="detailBg" v-if="state.law_sts == '5'" @click="event.toPage">您还未认证为我们的合作律师，无法查看案件详情。继续点击本页面完成注册、实名认证和我们的人工审核后，方可接案。</div>
						<div class="detailBg" v-if="state.law_sts == '1'" @click="event.toPage">您还未完成实名认证，点击本页面继续您的实名认证。</div>
						<div class="detailBg" v-if="state.law_sts == '4'" @click="event.toPage">您的账户还未通过我们的法务员的人工审核，点击这里催促他们。</div>
						<div :class="{'list':true,'mosaic':state.caseData.params.detailsFlag == '0'}" :style="'height:calc(80vh - 110px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;'">
							<ren-component v-if="state.caseId" :data="state.caseData" :id="state.caseId"></ren-component>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 1">
						<div class="detailBg" v-if="state.law_sts == '5'" @click="event.toPage">您还未认证为我们的合作律师，无法查看案件详情。继续点击本页面完成注册、实名认证和我们的人工审核后，方可接案。</div>
						<div class="detailBg" v-if="state.law_sts == '1'" @click="event.toPage">您还未完成实名认证，点击本页面继续您的实名认证。</div>
						<div class="detailBg" v-if="state.law_sts == '4'" @click="event.toPage">您的账户还未通过我们的法务员的人工审核，点击这里催促他们。</div>
						<div :class="{'list':true,'mosaic':state.caseData.params.detailsFlag == '0'}" :style="'height:calc(80vh - 110px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;'">
							<ht-component v-if="state.caseId" :data="state.caseData" :id="state.caseId" @fileStatus="event.getStatus"></ht-component>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 2">
						<div class="detailBg" v-if="state.law_sts == '5'" @click="event.toPage">您还未认证为我们的合作律师，无法查看案件详情。继续点击本页面完成注册、实名认证和我们的人工审核后，方可接案。</div>
						<div class="detailBg" v-if="state.law_sts == '1'" @click="event.toPage">您还未完成实名认证，点击本页面继续您的实名认证。</div>
						<div class="detailBg" v-if="state.law_sts == '4'" @click="event.toPage">您的账户还未通过我们的法务员的人工审核，点击这里催促他们。</div>
						<div :class="{'list':true,'mosaic':state.caseData.params.detailsFlag == '0'}" :style="'height:calc(80vh - 110px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;'">
							<je-component v-if="state.caseId" :data="state.caseData" :id="state.caseId"></je-component>
						</div>
					</template>
					<template v-slot:default v-if="state.tab1value == 3">
						<div class="detailBg" v-if="state.law_sts == '5'" @click="event.toPage">您还未认证为我们的合作律师，无法查看案件详情。继续点击本页面完成注册、实名认证和我们的人工审核后，方可接案。</div>
						<div class="detailBg" v-if="state.law_sts == '1'" @click="event.toPage">您还未完成实名认证，点击本页面继续您的实名认证。</div>
						<div class="detailBg" v-if="state.law_sts == '4'" @click="event.toPage">您的账户还未通过我们的法务员的人工审核，点击这里催促他们。</div>
						<div :class="{'list':true,'mosaic':state.caseData.params.detailsFlag == '0'}" :style="'height:calc(80vh - 110px);width: 100%;margin: 0;overflow-y: auto;-webkit-overflow-scrolling: touch;'" id="scroll">
							<nut-infiniteloading
							 containerId = 'scroll'
							 load-icon="loading1"
							 pull-icon="loading1"
							:use-window='false'
							:has-more="state.hasMore"
							:is-open-refresh="true"
							 @load-more="event.loadMore"
							 @refresh="event.refresh"
							>
								<log-component v-if="state.caseId" :data="state.caseData":id="state.caseId" :listData="state.listData"></log-component>
							</nut-infiniteloading>
						</div>
					</template>
				</nut-tabpane>
			</nut-tabs>
		</div>
	</div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref} from 'vue';
	import { Dialog, Toast } from '@nutui/nutui';
	import logComponent from '@/view/item/list'
	import jeComponent from '@/view/item/money'
	import htComponent from '@/view/item/contract'
	import renComponent from '@/view/item/people'
	import oss from 'ali-oss'
	export default {
		components:{
			logComponent,
			jeComponent,
			htComponent,
			renComponent
		},
		props:{
			id:String,
			data: Object
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const myElement = ref(null)
			const state = reactive ({
				tab1value: 0,
				height: 0,
				hasMore: false,
				list4: [
					{
						title:'债务人',
						paneKey: '0',
					},
					{
						title:'合同',
						paneKey: '1',
					},
					{
						title:'金额',
						paneKey: '2',
					},
					{
						title:'案件日志',
						paneKey: '3',
					}
				],
				listData:[],
				caseId: '',
				caseData: {
					params:{
						detailsFlag:'0'
					}
				},
				law_sts: '5',
				user: ''
				
			});
			onMounted(()=>{
				state.caseId = props.id
				state.caseData = props.data
				state.law_sts = localStorage.getItem('law_sts') || '5'
				state.user = localStorage.getItem('user')
			})
			const event = {
				toPage(){
					if(state.law_sts == '5'){
						if(state.user){
							proxy.$router.push({path:'/register'})
						}else{
							proxy.$router.push({path:'/login'})
						}
					}else if(state.law_sts == '1'){
						proxy.$router.push({path:'/register',query:{sm:true}})
					}else if(state.law_sts == '4'){
						location.href = 'https://work.weixin.qq.com/kfid/kfcd4ec604cb328d29e'
					}
				},
				getStatus(res){
					context.emit('fileStatus',{fileBox: res.fileBox,fileUrl:res.fileUrl})
				},
				getDetail(){
					state.detail = ''
					state.step = ''
					proxy.$axios.projectDetailsQuery(proxy.$route.query.id).then((res)=>{
						if(res.data.errorCode == '00000'){
							state.detail = res.data.data
							state.step = state.detail.projectDetails.projectProgress
							state.title = state.detail.projectDetails.projectName
							event.selectFocusOnProjects()
							if(['5','6','7'].indexOf(state.step) != -1){
								event.getVoteMsgById(state.projectId)
							}else{
								state.VoteData1 = []
								state.VoteData2 = []
								state.tpNum = 0
							}
						}
					})
				},
				tabClick(item){
					state.tab1value = item.paneKey
				},
				getDtList(curpage,fun){
					proxy.$axios.pageList(curpage,10,{projectId:state.projectId}).then(res=>{
						if(res.data.errorCode == '00000'){
							let data = res.data.data.records
							let obj = {}
							for(var i = 0;i<data.length;i++){
								let objId = data[i].createTime.split(' ')[0]
								if(obj[objId]){
									obj[objId].push(data[i])
								}else{
									obj[objId] = []
									obj[objId].push(data[i])
								}
							}
							if(curpage!=1){
								Object.keys(obj).forEach(key=>{
									if(state.listData[key]){
										state.listData[key] = state.listData[key].concat(obj[key])
									}else{
										state.listData[key] = obj[key]
									}
								})
							}else{
								state.listData = obj
							}
							if(curpage < res.data.data.pages){
								state.hasMore = true
							}else{
								state.hasMore = false
							}
							fun()
						}
					})
				},
			}
			return {
				state,
				event,
				myElement
			};
		}
	}
</script>

<style>
	.detail{
		position: relative;
	}
	.tab{
		text-align: left;
	}
	.tab .nut-tabs__titles{
		height: 40px;
	}
	.tab .nut-tabs__titles-item{
		color: #919191;
	}
	.tab .nut-tabs__titles-item.active{
		color: #4475DF;
	}
	.tab .nut-tabs__titles-item.active .nut-tabs__titles-item__line{
		background-image: url('../../assets/imgs/xia.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100%;
		width: 65px;
		height: 3px;
		margin-top: 18px;
		position: absolute;
		bottom: 0px;
	}
	.tab .nut-tabpane{
		padding: 0;
	}
	.line_icon{
		width: 3px;
		height: 15px;
		display: inline-block;
		background-image: url('../../assets/imgs/line.png');
		background-repeat: no-repeat;
		background-size: 100%;
		margin-right: 5px;
		position: relative;
		top: 3px;
	}
	.laberItem{
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		color: #555;
		margin: 0 20px;
		border-bottom: 1px solid #f0f0f0;
		align-items: center;
	}
	.laberItem>span{
		width: 40%;
		padding: 8px 0;
	}
	.laberItem>div{
		width: 60%;
	}
	.labelTit{
		background-color: #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
	}
	.edit_icon{
		width: 45px;
		height: 45px;
		background-image: url('../../assets/imgs/addItem.png');
		background-size: 100%;
		background-repeat: no-repeat;
		position: absolute;
		bottom: 80px;
		right: 30px;
	}
	.laberItem input{
		width: 100%;
		text-align: right;
		border: none;
		height: 35px;
		outline: none;
		line-height: 35px;
		background-color: transparent;
		color: #555;
	}
	.laberItem textarea{
		text-align: left;
		border: none;
		outline: none;
		background-color: #eee;
		width: calc(100% - 20px);
		padding: 10px;
		border-radius: 5px;
		margin: 10px 0;
		color: #555;
	}
	.laberItem select{
		border: none; 
		outline: none;
		width: 100%;
		text-align: right;
		border-radius: 0;
		background: none;
		-webkit-user-select:none;
		-moz-user-select: none;
		user-select: none;
		appearance: none;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		font-size: 14px;
		color: #555;
		direction: rtl;
	}
	.laberItem select:focus{
	    border: 0px;
	    box-shadow: 0 0 0px 0px #fff;
	}
	.laberItem select option{
		text-align: right;
		color: #555;
	}
	.laberItem select:disabled {
	    color: #555;
		opacity: 1;
	}
	.upload_btn{
		width: 80px;
		height: 80px;
		background: #eee;
		text-align: center;
		line-height: 80px;
		border-radius: 5px;
		margin: 10px 10px 10px 0;
		position: relative;
	}
	.imgList .upload_btn{
		flex-shrink: 0;
	}
	.upload_btn input{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
	.upload_btn img{
		width: 100%;
		height: 100%;
	}
	.detailBg{
		width: calc(100% - 40px);
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 16px;
		padding: 20px;
		display: flex;
		align-items: center;
		z-index: 999;
	}
</style>