<template>
	<div class="money">
		<table>
			<tr><td>逾期总额</td><td colspan="2"><nut-price :price="state.caseDetailInfo.icmAmtInvolved.overAmt" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.overAmt && state.caseDetailInfo.icmAmtInvolved.overAmt.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.overAmt.toString().split('.')[1].length:0"/></td></tr>
			<tr><td>合同借款本金</td><td colspan="2"><nut-price :price="state.caseDetailInfo.icmAmtInvolved.pactPrcp" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.pactPrcp && state.caseDetailInfo.icmAmtInvolved.pactPrcp.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.pactPrcp.toString().split('.')[1].length:0"/></td></tr>
			<tr><td>合同实际放款金额</td><td><nut-price :price="state.caseDetailInfo.icmAmtInvolved.putoutAmtReal" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.putoutAmtReal && state.caseDetailInfo.icmAmtInvolved.putoutAmtReal.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.putoutAmtReal.toString().split('.')[1].length:0"/></td><td>受托支付：{{event.getLabel(state.caseDetailInfo.icmAmtInvolved.payType,state.payType)}}</td></tr>
			<tr><td>累计归还本金</td><td colspan="2"><nut-price :price="state.caseDetailInfo.icmAmtInvolved.repayPrcpSum" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.repayPrcpSum && state.caseDetailInfo.icmAmtInvolved.repayPrcpSum.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.repayPrcpSum.toString().split('.')[1].length:0"/></td></tr>
			<tr><td>累计归还利息</td><td colspan="2"><nut-price :price="state.caseDetailInfo.icmAmtInvolved.repayIntstSum" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.repayIntstSum && state.caseDetailInfo.icmAmtInvolved.repayIntstSum.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.repayIntstSum.toString().split('.')[1].length:0"/></td></tr>
			<tr><td>未还本金</td><td colspan="2"><nut-price :price="state.caseDetailInfo.icmAmtInvolved.repayPrcpBal" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.repayPrcpBal && state.caseDetailInfo.icmAmtInvolved.repayPrcpBal.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.repayPrcpBal.toString().split('.')[1].length:0"/></td></tr>
			<tr><td>未还利（手续费）</td><td><nut-price :price="state.caseDetailInfo.icmAmtInvolved.overIntst" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.overIntst && state.caseDetailInfo.icmAmtInvolved.overIntst.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.overIntst.toString().split('.')[1].length:0"/></td><td>利率：{{state.caseDetailInfo.icmAmtInvolved.rate}}</td></tr>
			<tr><td>逾期利息</td><td><nut-price :price="state.caseDetailInfo.icmAmtInvolved.overRate" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.overRate && state.caseDetailInfo.icmAmtInvolved.overRate.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.overRate.toString().split('.')[1].length:0"/></td><td>逾期利率：{{state.caseDetailInfo.icmAmtInvolved.overRate}}</td></tr>
			<tr><td>罚息</td><td><nut-price :price="state.caseDetailInfo.icmAmtInvolved.penalty" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.penalty && state.caseDetailInfo.icmAmtInvolved.penalty.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.penalty.toString().split('.')[1].length:0"/></td><td>罚息利率：{{state.caseDetailInfo.icmAmtInvolved.penaltyRate}}</td></tr>
			<tr><td>违约金</td><td><nut-price :price="state.caseDetailInfo.icmAmtInvolved.breachPenalty" :thousands="true" :decimal-digits="(state.caseDetailInfo.icmAmtInvolved.breachPenalty && state.caseDetailInfo.icmAmtInvolved.breachPenalty.toString().split('.')[1])?state.caseDetailInfo.icmAmtInvolved.breachPenalty.toString().split('.')[1].length:0"/></td><td>算法：{{state.caseDetailInfo.icmAmtInvolved.algorithm}}</td></tr>
			<tr><td>借款期数</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.term}}</td></tr>
			<tr><td>期数单位</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.termUnit}}</td></tr>
			<tr><td>借款起年月日</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.payBeginDate}}</td></tr>
			<tr><td>借款止年月日</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.payEndDate}}</td></tr>
			<tr><td>合同约定首次还款日期</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.firstRepayDateShould}}</td></tr>
			<tr><td>最后还款日期</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.endRepayDate}}</td></tr>
			<tr><td>首次违约日期</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.firstOverDate}}</td></tr>
			<tr><td>宽期限</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.period }}</td></tr>
			<tr><td>累计逾期天数</td><td colspan="2">{{state.caseDetailInfo.icmAmtInvolved.overDays}}</td></tr>
		</table>
	</div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance, toRefs } from 'vue';
import { Toast,Dialog } from '@nutui/nutui';
	export default {
		name:'money',
		props:{
			id:String,
			data: Object
		},
		setup(props,context) {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				projectId: '',
				caseData: {},
				caseDetailInfo:{
				  icmAmtInvolved:{
				    "createBy":null,
					"createTime":null,
					"updateBy":null,
					"updateTime":null,
					"remark":null,
					"isDelete":null,
					"ids":null,
					"id":"240321152317788556",
					"caseId":240321152311658640,
					"overAmt":23062.600000,
					"pactPrcp":15600.000000,
					"putoutAmtReal":15600.000000,
					"payType":"1",
					"payChannel":"BAOFUYF",
					"repayPrcpSum":0.000000,
					"repayIntstSum":0.000000,
					"repayPrcpBal":15600.000000,
					"repayIntstBal":1048.320000,
					"rate":1.120000,
					"overIntst":6414.280000,
					"overRate":3.000000,
					"penalty":'200',
					"penaltyRate":'20',
					"breachPenalty":'300',
					"term":"6",
					"termUnit":"月",
					"payBeginDate":"2022-10-09",
					"payEndDate":"2023-04-09",
					"firstRepayDateShould":"2022-10-09",
					"endRepayDate":'2024-01-20',
					"firstOverDate":"2022-11-09",
					"period":"3",
					"overDays":"498",
					"regTime":"2024-03-21",
					"regDate":null,
					"lastModTime":"2024-03-21",
					"lastDate":null,
					"opName":null,
					"opNo":null,
					"ext1":null,
					"ext2":null,
					"ext3":null,
					"ext4":null,
					"ext5":null,
					"putoutRealDate":"2022-10-09",
					"algorithm":'12',
					"otherAmt":null
				  }
				},
				payType:[]
			})
			onMounted(()=>{
				state.projectId = props.id
				state.caseData = props.data
				proxy.$common.getDict('pay_type').then(data=>{
					state.payType = data
				})
				if(state.caseData.params.detailsFlag == '0') return
				event.detail()
			})
			const event = {
				getLabel(value,data){
					return proxy.$common.getLabel(value,data)
				},
				back(){
					proxy.$router.go(-1)
				},
				detail(){
					proxy.$axios.getIcmAmtInvolvedByCaseId(state.projectId).then(res=>{
						if(res.data.code == 200){
							state.caseDetailInfo.icmAmtInvolved = res.data.data
						}
					})
				},
				save(){
					proxy.$axios.editProjectCapitalizeInfoAndFinanceInfo({projectDetailsId:state.projectId,projectCapitalizeInfoList:state.list,projectFinanceInfoList:state.listF}).then(res =>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							proxy.$router.go(-1)
						}
					})
				},
			}
			return { state,event,toRefs };
		}
	}
</script>

<style>
	.money{
		overflow: hidden;
	}
	.money table{
		font-size: 12px;
		color: #555;
		width: calc(100% - 30px);
		margin: 20px 15px;
		border-collapse: collapse;
	}
	.money tr{
	}
	.money td{
		border: 1px solid #ddd;
		padding: 5px 10px
	}
	.money .nut-price{
		color: #555;
	}
	.money .nut-price--large, .nut-price--point{
		font-size: 12px;
	}
	.money .nut-price--decimal-large, .nut-price--symbol-large{
		font-size: 12px !important;
	}
</style>